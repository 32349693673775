<template>
  <v-card class="confirm-privacy-options-modal pa-4">
    <h2 class="primary--text">{{ $t("profile.noPrivacy.warning") }}</h2>
    <v-row no-gutters justify="end" class="mt-4">
      <v-btn
        depressed
        outlined
        large
        text
        class="primary--text"
        v-html="$t('profile.noPrivacy.continue')"
        @click="submit"
      />
      <v-btn
        depressed
        outlined
        large
        text
        class="primary white--text ml-2"
        v-html="$t('profile.noPrivacy.edit')"
        @click="close"
      />
    </v-row>
  </v-card>
</template>
<style lang="scss">
.confirm-privacy-options-modal {
  h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
  }
  .v-btn {
    font-size: 100%;
  }
}
</style>

<script>
export default {
  methods: {
    close() {
      this.$emit("submit", false);
    },
    submit() {
      this.$emit("submit", true);
    }
  }
};
</script>
